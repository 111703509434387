import styles from "./index.module.css";
import React from "react";

export default function Header() {
    return (
        <header className={styles.header}>
            <img src={'./icon-250.png'} alt={'Logo'} width={40} height={40} />
            <a href={"https://www.myloverr.com/"}>
                <h2 color={'#ffffff'} style={{fontWeight: '600', color: 'white'}}>MyLoverr</h2>
            </a>

            {/*<Select variant={'outlined'}>*/}
            {/*    <MenuItem value={'en'}><IconFlagUS/></MenuItem>*/}
            {/*    <MenuItem value={'pt'}><IconFlagBR/></MenuItem>*/}
            {/*</Select>*/}
        </header>
    );
}