import React, {useState} from "react";
import styles from './index.module.css'
import {CoupleComponent} from "../coupleComponent";

export interface PreviewProps {
    token?: string;
    email?: string;
    coupleName: string;
    startDate: any;
    files?: any;
    letter: string;
    songID: string;
    plan: string;
}

export function Preview(props: PreviewProps) {

    return (
        <div style={{
            width: "100%",
            display: 'flex',
            flexDirection: 'column',
            gap: 24,
            minWidth: 300,
            position: 'relative',
            height: 730,
            alignItems: 'center'
        }}>
            <span color={'#ffffff'} style={{color: '#ffffff'}}>🛠️ Preview & Finish</span>
            <section style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                overflow: 'hidden',
                maxHeight: 600,
            }}>
                <img style={{position: 'absolute'}} width={700} height={700} src={'/iphone.webp'} alt={"phone"}/>
                <div style={{height: props.files && props.files.length > 0 ? 175 : 100}}></div>
                <section className={styles.scroll} style={{
                    display: 'flex', flexDirection: 'column', gap: 24,
                    height: 600,
                    overflowY: 'auto',
                    scrollMargin: 20,
                    overflowX: 'hidden',
                    zIndex: 3,
                }}>
                    <div style={{maxWidth: 230}}>
                        <div style={{
                            backgroundColor: 'white',
                            width: '100%',
                            // height: '100%',
                            display: 'flex',
                            borderRadius: 6,
                            height: 'auto',
                            padding: '5px 15px',
                            placeContent: 'center',
                            marginBottom: 24
                        }}>
                                {/*# TODO: Delimitar max limit do width*/}
                                <span style={{
                                    color: 'black',
                                    display: 'inline-block',
                                    fontSize: 12,
                                    overflow: 'hidden',
                                    textOverflow: 'contains',
                                    textWrap: 'nowrap'
                                }}>https://myloverr.com/{props.coupleName}</span>
                        </div>
                        <CoupleComponent token={props.token} coupleName={props.coupleName} startDate={props.startDate}
                                         letter={props.letter} songID={props.songID} plan={props.plan}
                                         files={props.files}></CoupleComponent>
                    </div>
                </section>
            </section>
        </div>
    );
}