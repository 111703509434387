import React, {useState} from "react";
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import {useReward} from 'react-rewards';
import styles from "./index.module.css";
import {PreviewProps} from "../preview";
import {Countdown} from "../countdown";
import {Slideshow} from "../uploader";
import {toast} from "react-toastify";
import GiftDispatcher from "../../utils/Dispatchers/GiftDispatcher";

export function CoupleComponent(props: PreviewProps) {
    const dispatcher = new GiftDispatcher();
    const [opened, setOpened] = useState(false);
    const {
        reward,
        isAnimating
    } = useReward('rewardId', 'emoji', {emoji: ['💖', '💘', '💝', '💖', '💗', '💓', '💞', '❣️', '💌', '😍']});

    function startRewards() {
        setTimeout(() => {
            reward();
            startRewards();
        }, 15000);
    }

    async function edit() {
        if (!props?.coupleName) {
            toast.error('An error occurred. Please try again later or contact support.')
            return
        }

        await dispatcher.sendEditEmail(props.coupleName).then(
            data => {
                toast.success('Email sent to ' + props?.email)
            },
            err => {
                toast.error('An error occurred. Please try again later or contact support.')
            }
        )
    }

    return (
        <div style={{display: "flex", flexDirection: 'column', alignItems: 'center', gap: 42}}>
            <span id="rewardId" style={{position: 'fixed', top: -40, zIndex: 3}}/>
            <Button
                disabled={isAnimating}
                onClick={() => {
                    window.scrollTo(0, 0);
                    reward();
                    startRewards();
                    setOpened(true);
                }}
                style={{
                    position: 'absolute', top: '40%', color: "#fb6f92", width: 'max-content',
                    borderColor: "#fb6f92", display: !opened ? 'flex' : 'none'
                }}
                size={"large"}
                variant={"outlined"}
            >
                <p color={"#fb6f92"} style={{fontSize: 28}}>💝 Open</p>
            </Button>
            <div style={{
                display: opened ? 'flex' : 'none',
                flexDirection: 'column',
                gap: 42,
                alignItems: 'center'
            }}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {opened && props.files && <Slideshow images={props.files}></Slideshow>}
                </div>
                <div style={{display: 'flex', flexDirection: 'column', gap: 32, alignItems: 'center', width: '100%'}}>
                    <p style={{color: 'white', textAlign: 'center', fontSize: 18, display: 'inline-block'}}>💖 Together 💖</p>
                    {props.startDate && <Countdown startDate={props.startDate}></Countdown>}
                    <Divider className={styles.divider}
                             sx={{backgroundColor: 'rgb(96, 96, 96)', width: '70%', placeSelf: 'center'}}
                             flexItem={true}></Divider>
                    {props.letter && (<>
                        <em style={{fontSize: 22, fontStyle: 'normal'}}>💌</em>
                        <em className={styles.letter}>{props.letter?.toString()}</em>
                    </>)}
                </div>
                {props.songID && <iframe title="YouTube video player" frameBorder="0"
                                         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                         referrerPolicy="strict-origin-when-cross-origin"
                                         className={styles.iframe}
                    // TODO: Voltar com o som
                    // src={'https://www.youtube.com/embed/' + props.songID + '?si=j0NRg6bl6kEAZ8E0&autoplay=1&mute=2&start=0'}></iframe>}
                                         src={'https://www.youtube.com/embed/' + props.songID + '?si=j0NRg6bl6kEAZ8E0&autoplay=1&mute=0&start=0'}></iframe>}
                <Button variant={"outlined"} onClick={edit}>Edit page</Button>
            </div>
        </div>
    );
}