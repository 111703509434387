"use client";
import React, {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import {createSvgIcon, FormControlLabel, Radio, RadioGroup, TextareaAutosize} from "@mui/material";
// import {Poppins} from "next/font/google";
import {FileUploader} from "../uploader";
import {DateTimePicker, LocalizationProvider, PickerValidDate} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import styles from "./index.module.css";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import dayjs, {Dayjs} from "dayjs";
import {Preview} from "../preview";
import {useSearchParams} from "react-router-dom";
import {PaymentModal, poppins} from "../payment-modal";

// const poppins = Poppins({
//     weight: '400',
//     subsets: ['latin'],
// })


const BootstrapInput = styled(InputBase)(({theme, style, placeholder}) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: 'rgb(20, 20, 20)',
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}));

const CalendarIcon = createSvgIcon(
    <CalendarMonthIcon style={{color: 'white !important'}}/>,
    'CalendarIcon',
);

export interface FormProps {
    coupleName?: string,
    songID?: string,
    startDate?: any,
    files?: any,
    letter?: string,
    email?: string,
    token?: string
}

export default function Form(props: FormProps) {
    const [coupleName, setCoupleName] = useState("");
    const [songID, setSongID] = useState("");
    const [startDate, setStartDate] = React.useState<Dayjs | undefined>(undefined);
    const [files, setFiles] = React.useState([]);
    const [letter, setLetter] = React.useState("");

    useEffect(() => {
        if (!props)
            return
        if (props.songID)
            setSongID(props.songID);
        if (props.files)
            setFiles(props.files);
        if (props.letter)
            setLetter(props.letter);
        if (props.startDate)
            setStartDate(dayjs(props.startDate));
        if (props.coupleName)
            setCoupleName(props.coupleName);
    }, []);

    const [searchParams, setSearchParams] = useSearchParams();

    function getInitialPlan() {
        const plan = searchParams.get('plan')
        return plan ? plan : 'premium';
    }

    // function getInitialCoupon() {
    //     const coupon = searchParams.get('coupon')
    //     return coupon ? coupon : '';
    // }

    const [selectedPlan, setSelectedPlan] = React.useState(getInitialPlan());

    // const [coupon, setCoupon] = React.useState(getInitialCoupon());

    function plansChoices() {
        function change(plan: string) {
            setSelectedPlan(plan);
        }

        return (
            <FormControl sx={{width: '100%', padding: 1}} className={`${poppins.className} ${styles.inputWidth}`}>
                <span style={{color: 'white'}}>💰 Subscription</span>
                <RadioGroup
                    sx={{padding: 1, color: '#ffffff !important'}}
                    color={'#ffffff'}
                    defaultValue={selectedPlan}
                >
                    <FormControlLabel value="basic" onChange={() => change('basic')}
                                      control={<Radio size={'small'} sx={{color: 'white'}}/>}
                                      label="2 years, 3 photos & no music - $5"/>
                    <FormControlLabel value="premium" onChange={() => change('premium')}
                                      control={<Radio size={'small'} sx={{color: 'white'}}/>}
                                      label="Forever, 10 photos & with music - $10"/>
                </RadioGroup>
            </FormControl>
        );
    }

    function startDateComponent() {
        function change(value: PickerValidDate | null) {
            if (!value || value === null)
                return
            setStartDate(dayjs(value.toDate()));
        }

        return (
            <FormControl className={styles.inputWidth} sx={{m: 1, width: '100%', display: 'flex', alignItems: 'start'}}
                         variant="standard">
                <span style={{color: 'white'}} id="demo-customized-select-label">📅 Relationship start date</span>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                        <DateTimePicker
                            onOpen={() => {
                                setTimeout(() => {
                                    const el = document.activeElement;
                                    if (el) {
                                        (el as HTMLElement).blur();
                                    }
                                });
                            }}
                            disableFuture={true}
                            value={startDate}
                            slots={{openPickerIcon: CalendarIcon}}
                            sx={{color: 'white !important', width: '230px', backgroundColor: 'rgb(20, 20, 20)'}}
                            onAccept={(value) => change(value)}
                            className={styles.datePicker}/>
                    </DemoContainer>
                </LocalizationProvider>
            </FormControl>);
    }

    function coupleNameComponent() {
        function change(event: any) {
            const value = event.target.value;
            const sanitized = value.replaceAll(' ', '-').toLowerCase();
            setCoupleName(sanitized);
        }

        return (
            <FormControl className={styles.inputWidth} sx={{m: 1, gap: 1, width: '100%',}} variant="standard">
                <span style={{color: 'white'}}>💖 Couple's name <em
                    style={{fontSize: 12}}>(Don't use emoji)</em></span>
                <BootstrapInput style={{color: 'white'}} placeholder={"Sara and Alex"}
                                className={poppins.className}
                                value={coupleName}
                                onChange={change}/>
            </FormControl>
        );
    }

    function letterComponent() {

        function change(event: any) {
            setLetter(event.target.value);
        }

        return (
            <FormControl className={styles.inputWidth} sx={{m: 1, width: '98%', gap: 1}} variant="filled">
                <span color={"#ffffff"} style={{color: '#ffffff'}}>💌 Love letter <em
                    style={{fontSize: 12}}>(Optional)</em></span>
                <TextareaAutosize className={poppins.className}
                                  value={letter}
                                  placeholder={"Message"} onChange={change} style={{
                    backgroundColor: 'rgb(20, 20, 20)',
                    fontSize: 18,
                    padding: 10,
                    color: 'white',
                    borderRadius: 4,
                    minHeight: 200,
                }}></TextareaAutosize>
            </FormControl>
        );
    }

    function songComponent() {
        function getVideoIDFromUrl(url: string | null) {
            const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
            const match = url?.match(regExp);
            if (match && match[2].length == 11) {
                return match[2];
            } else {
                return null;
            }
        }

        function change(event: any) {
            // const value = getVideoIDFromUrl(event.target.value)
            const value = event.target.value;
            if (!value)
                return
            setSongID(value);
        }

        return (
            <FormControl className={styles.inputWidth} sx={{m: 1, gap: 1, width: '97%'}} variant="standard">
                <span style={{color: 'white'}}>🔗 YouTube URL</span>
                <BootstrapInput style={{color: 'white'}} placeholder={"https://youtube.com/..."}
                                className={poppins.className}
                                value={songID}
                                onChange={change}/>
            </FormControl>
        );
    }

    return (
        <section className={styles.mainContainer}>
            <div style={{display: 'flex', flexDirection: 'column', gap: 24, alignItems: 'start', minWidth: 500}}>
                {plansChoices()}
                <div style={{
                    display: 'flex',
                    flexDirection: 'column-reverse',
                    gap: 24,
                    width: '100%',
                    alignItems: 'start'
                }}>
                    {startDateComponent()}
                    {selectedPlan == 'premium' && songComponent()}
                    {coupleNameComponent()}
                </div>
                {letterComponent()}
                <FileUploader setFiles={setFiles} maxImages={selectedPlan === 'premium' ? 10 : 3}/>
            </div>

            <div style={{display: 'flex', flexDirection: 'column', gap: 24}}>
                <Preview token={props.token} coupleName={coupleName} startDate={startDate} files={files} letter={letter}
                         songID={songID} plan={selectedPlan}></Preview>

                <div style={{width: '100%', display: 'flex', placeContent: 'center', paddingBottom: 100}}>
                    <PaymentModal token={props.token} email={props.email} coupleName={coupleName} startDate={startDate}
                                  files={files} letter={letter}
                                  songID={songID} plan={selectedPlan}></PaymentModal>
                </div>
            </div>

        </section>
    );
}