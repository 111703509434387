import React, {useEffect, useTransition} from "react";
import GiftDispatcher from "../../utils/Dispatchers/GiftDispatcher";
import {toast} from "react-toastify";
import dayjs from "dayjs";
import FormControl from "@mui/material/FormControl";
import styles from "../form/index.module.css";
import TextField from "@mui/material/TextField";
import {Button} from "@mui/material";
import AddCardIcon from "@mui/icons-material/AddCard";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ReactGA from "react-ga4";
// import TagManager from '@sooro-io/react-gtm-module'

ReactGA.initialize('G-4G5GWVY7F8')

export const poppins = {className: ''}
const styleModal = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
    color: 'black'
};

interface PaymentProps {
    token?: string,
    coupleName: string,
    startDate: any,
    files?: any,
    letter: string,
    songID: string,
    plan: string,
    email?: string
    // coupon?: string,
}


export function PaymentModal(props: PaymentProps) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [email, setEmail] = React.useState<string>(props?.email ? props.email : '');
    const [validEmail, setValidEmail] = React.useState(false);
    const giftDispatcher = new GiftDispatcher();

    async function createGift() {
        function sanitizeBodyParam(param: any) {
            return param ? param?.toString() : "";
        }

        const body = {
            couple_slug: sanitizeBodyParam(props.coupleName),
            email: sanitizeBodyParam(email),
            relationship_start_date: props?.startDate ? props.startDate?.toISOString() : '',
            youtube_id: sanitizeBodyParam(props.songID),
            letter: sanitizeBodyParam(props.letter),
            // coupon: sanitizeBodyParam(props.coupon),
            files: props.files,
            plan: sanitizeBodyParam(props.plan),
        }

        function sendPurchaseEvent() {
            ReactGA.gtag('event', 'conversion', {
                'value': props?.plan === 'premium' ? 10.0 : 3.0,
                'send_to': 'AW-614381859/DQVhCKPFm_kZEKPy-qQC',
                'currency': 'USD',
                'transaction_id': ''
            })
        }

        await giftDispatcher.create(body).then(
            (data) => {
                sendPurchaseEvent();
                toast.success('Page created')
                window.location.replace(data['url'].toString());
            },
            err => {
                toast.error(err.response.data);
            }
        )
    }


    async function editGift() {
        if (!props.token)
            return

        const body = {
            couple_slug: props.coupleName?.toString(),
            email: email?.toString(),
            relationship_start_date: dayjs(props.startDate).toISOString(),
            youtube_id: props.songID?.toString(),
            letter: props.letter?.toString(),
            files: props.files,
            plan: props.plan,
        }
        await giftDispatcher.update(body, props.token).then(
            (data) => {
                toast.success('Page updated')
                window.location.replace('/' + data['couple_slug'].toString());
            },
            err => {
                toast.error(err.response.data);
            }
        )
    }

    function EmailComponent() {
        const [disableInput, setDisableInput] = React.useState(false);
        const [isPending, startTransition] = useTransition();

        useEffect(() => {
            if (!props?.email)
                return
            setEmail(props.email?.toString())
            setValidEmail(true);
            setDisableInput(true);
        }, []);

        function validateEmail() {
            const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
            const validEmail = email?.match(emailRegex);
            setValidEmail(!!validEmail);
        }

        function change(event: any) {
            startTransition(() => {
                const value = event?.target.value;
                setEmail(value);
                validateEmail();
            })
        }

        return (
            <div>
                <FormControl className={styles.inputWidth}
                             sx={{m: 1, gap: 2, width: '100%'}}
                             variant="standard">
                    <span style={{color: 'black'}}>Enter your email to receive the gift:</span>

                    <TextField className={poppins.className}
                               disabled={disableInput}
                               placeholder="email"
                               variant="outlined"
                               value={email}
                               onChange={change}
                               onBlur={change}
                               autoFocus
                    />
                    {/*<Button startIcon={<AssuredWorkloadIcon style={{fontSize: 18}}/>} disabled={validEmail} sx={{textTransform: 'none'}} color={'black'} className={poppins.className}*/}
                    <Button onClick={!disableInput ? createGift : editGift}
                            startIcon={!disableInput ? <AddCardIcon/> : <EditIcon/>} disabled={!validEmail}
                            sx={{textTransform: 'none'}} className={poppins.className}
                            fullWidth={true} variant={"outlined"} size={'large'}
                    >
                        {!disableInput ? <h3>Pay</h3> : <h3>Edit</h3>}
                    </Button>
                </FormControl>
            </div>
        );
    }

    const invalidBasicInputs = props.coupleName == '' || props.files.length == 0;

    return (
        <div style={{width: '100%'}}>
            <Button fullWidth={true} variant={"contained"}
                // TODO: Voltar com ele
                // disabled={invalidBasicInputs}
                    sx={{height: 'fit-content', backgroundColor: '#F51D53', padding: 1.5}}
                    onClick={handleOpen}
            >Finish</Button>
            <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
            >
                <Box sx={styleModal}>
                    <EmailComponent></EmailComponent>
                </Box>
            </Modal>
        </div>
    )
}