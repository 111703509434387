"use client";
import React, {useEffect, useState} from "react";
import dayjs from "dayjs";
import globalStyles from "./index.module.css";
import GiftDispatcher from "../../utils/Dispatchers/GiftDispatcher";
import {CoupleComponent} from "../../components/coupleComponent";
import {MainButton} from "../../components/main-button";
import {useParams} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {poppins} from "../../components/payment-modal";
import Button from "@mui/material/Button";
import AddCardIcon from "@mui/icons-material/AddCard";


export default function CouplePageComponent() {
    // TODO: Remover coupleName
    const [coupleName, setCoupleName] = useState('asdasd');
    const [songID, setSongID] = useState('asd');
    const [startDate, setStartDate] = React.useState(dayjs());
    const [files, setFiles] = React.useState([]);
    const [email, setEmail] = React.useState("");
    const [letter, setLetter] = React.useState('oi');
    const [token, setToken] = React.useState('');
    const [plan, setPlan] = React.useState("premium");
    const dispatcher = new GiftDispatcher();
    const [loading, setloading] = React.useState(true);
    const [notFound, setNotFound] = React.useState(false);
    const [paymentUrl, setPaymentUrl] = React.useState('');
    const [paymentRequired, setPaymentRequired] = React.useState(false);
    const {slug} = useParams();

    function getGift() {
        if (!slug)
            return;
        let couple_slug = slug.toString();
        dispatcher.get(couple_slug).then(
            data => {
                setLetter(data['letter'])
                setCoupleName(data['couple_slug'])
                setStartDate(dayjs(data['relationship_start_date']))
                setSongID(data['youtube_id'])
                setFiles(data['files'])
                setEmail(data['email'])
                setToken(data['token'])
            },
            err => {
                if (err.status === 404)
                    setNotFound(true)
                if (err.status === 402) {
                    setPaymentRequired(true)
                    setPaymentUrl(err.response.data.url)
                }
            }
        ).finally(() => setloading(false));
    }

    useEffect(() => {
        getGift();
    }, []);

    function goToStripeCheckout() {
        return window.location.replace(paymentUrl);
    }

    return (
        <section className={globalStyles.page} style={{minHeight: '100vh'}}>
            <main className={globalStyles.main}>
                {!notFound && !loading && paymentRequired && (
                    <div style={{position: 'relative', zIndex: 100, top: '50vh'}}>
                        <Button onClick={goToStripeCheckout}
                                startIcon={<AddCardIcon/>}
                                sx={{textTransform: 'none'}} className={poppins.className}
                                fullWidth={true} variant={"contained"} size={'large'}
                        >
                            <h3>Payment Required</h3>
                        </Button>
                    </div>)
                }
                <div style={{padding: 30, width: '100%', filter: paymentRequired ? 'blur(20px)' : ''}}>
                    {!notFound && !loading &&
                        <CoupleComponent token={token} email={email} coupleName={coupleName}
                                         startDate={startDate} files={files} letter={letter}
                                         songID={songID} plan={plan}/>
                    }
                    {notFound && (
                        <div style={{display: 'flex', flexDirection: 'column', gap: 12, alignItems: 'center'}}>
                            <p>Available Link</p>
                            <MainButton width={300}></MainButton>
                        </div>
                    )}
                </div>
            </main>
            <ToastContainer/>
        </section>
    )
}